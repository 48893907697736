.sidebar {
  // scss-docs-start sidebar-css-vars
  --#{$prefix}sidebar-width: #{$sidebar-width};
  --#{$prefix}sidebar-bg: #{$sidebar-bg};
  --#{$prefix}sidebar-padding-x: #{$sidebar-padding-x};
  --#{$prefix}sidebar-padding-y: #{$sidebar-padding-y};
  --#{$prefix}sidebar-color: #{$sidebar-color};
  --#{$prefix}sidebar-border-width: #{$sidebar-border-width};
  --#{$prefix}sidebar-border-color: #{$sidebar-border-color};
  --#{$prefix}sidebar-brand-color: #{$sidebar-brand-color};
  --#{$prefix}sidebar-brand-height: #{$sidebar-brand-height};
  --#{$prefix}sidebar-brand-bg: #{$sidebar-brand-bg};
  --#{$prefix}sidebar-header-height: #{$sidebar-header-height};
  --#{$prefix}sidebar-header-bg: #{$sidebar-header-bg};
  --#{$prefix}sidebar-header-padding-x: #{$sidebar-header-padding-x};
  --#{$prefix}sidebar-header-padding-y: #{$sidebar-header-padding-y};
  --#{$prefix}sidebar-footer-bg: #{$sidebar-footer-bg};
  --#{$prefix}sidebar-footer-height: #{$sidebar-footer-height};
  --#{$prefix}sidebar-footer-padding-x: #{$sidebar-footer-padding-x};
  --#{$prefix}sidebar-footer-padding-y: #{$sidebar-footer-padding-y};
  --#{$prefix}sidebar-toggler-bg: #{$sidebar-toggler-bg};
  --#{$prefix}sidebar-toggler-height: #{$sidebar-toggler-height};
  --#{$prefix}sidebar-toggler-indicator: #{escape-svg($sidebar-toggler-indicator-icon)};
  --#{$prefix}sidebar-toggler-indicator-width: #{$sidebar-toggler-indicator-width};
  --#{$prefix}sidebar-toggler-indicator-height: #{$sidebar-toggler-indicator-height};
  --#{$prefix}sidebar-toggler-hover-bg: #{$sidebar-toggler-hover-bg};
  --#{$prefix}sidebar-toggler-indicator-hover: #{escape-svg($sidebar-toggler-indicator-hover-icon)};

  --#{$prefix}sidebar-narrow-width: #{$sidebar-narrow-width};

  --#{$prefix}sidebar-nav-title-padding-x: #{$sidebar-nav-title-padding-x};
  --#{$prefix}sidebar-nav-title-padding-y: #{$sidebar-nav-title-padding-y};
  --#{$prefix}sidebar-nav-title-margin-top: #{$sidebar-nav-title-margin-top};
  --#{$prefix}sidebar-nav-title-color: #{$sidebar-nav-title-color};
  --#{$prefix}sidebar-nav-link-padding-x: #{$sidebar-nav-link-padding-x};
  --#{$prefix}sidebar-nav-link-padding-y: #{$sidebar-nav-link-padding-y};
  --#{$prefix}sidebar-nav-link-color: #{$sidebar-nav-link-color};
  --#{$prefix}sidebar-nav-link-bg: #{$sidebar-nav-link-bg};
  --#{$prefix}sidebar-nav-link-border-color: #{$sidebar-nav-link-border-color};
  --#{$prefix}sidebar-nav-link-border: #{$sidebar-nav-link-border-width} solid var(--#{$prefix}sidebar-nav-link-border-color);
  --#{$prefix}sidebar-nav-link-border-radius: #{$sidebar-nav-link-border-radius};
  --#{$prefix}sidebar-nav-link-active-color: #{$sidebar-nav-link-active-color};
  --#{$prefix}sidebar-nav-link-active-bg: #{$sidebar-nav-link-active-bg};
  --#{$prefix}sidebar-nav-link-active-icon-color: #{$sidebar-nav-link-active-icon-color};
  --#{$prefix}sidebar-nav-link-disabled-color: #{$sidebar-nav-link-disabled-color};
  --#{$prefix}sidebar-nav-link-disabled-icon-color: #{$sidebar-nav-link-disabled-icon-color};
  --#{$prefix}sidebar-nav-link-hover-color: #{$sidebar-nav-link-hover-color};
  --#{$prefix}sidebar-nav-link-hover-bg: #{$sidebar-nav-link-hover-bg};
  --#{$prefix}sidebar-nav-link-hover-icon-color: #{$sidebar-nav-link-hover-icon-color};
  --#{$prefix}sidebar-nav-icon-width: #{$sidebar-nav-icon-width};
  --#{$prefix}sidebar-nav-icon-height: #{$sidebar-nav-icon-height};
  --#{$prefix}sidebar-nav-icon-font-size: #{$sidebar-nav-icon-font-size};
  --#{$prefix}sidebar-nav-link-icon-color: #{$sidebar-nav-link-icon-color};
  --#{$prefix}sidebar-nav-group-bg: #{$sidebar-nav-group-bg};
  --#{$prefix}sidebar-nav-group-items-padding-y: #{$sidebar-nav-group-items-padding-y};
  --#{$prefix}sidebar-nav-group-items-padding-x: #{$sidebar-nav-group-items-padding-x};
  --#{$prefix}sidebar-nav-group-indicator: #{escape-svg($sidebar-nav-group-indicator-icon)};
  --#{$prefix}sidebar-nav-group-indicator-hover: #{escape-svg($sidebar-nav-group-indicator-hover-icon)};
  --#{$prefix}sidebar-nav-group-toggle-show-color: #{$sidebar-nav-group-toggle-show-color};
  // scss-docs-end sidebar-css-vars

  position: relative;
  display: flex;
  // $sidebar-width is the width of the columns
  flex: 0 0 var(--#{$prefix}sidebar-width);
  flex-direction: column;
  // put the nav on the left
  order: -1;
  width: var(--#{$prefix}sidebar-width);
  padding: var(--#{$prefix}sidebar-padding-y) var(--#{$prefix}sidebar-padding-x);
  color: var(--#{$prefix}sidebar-color);
  background: var(--#{$prefix}sidebar-bg);
  @include ltr-rtl("border-right", var(--#{$prefix}sidebar-border-width) solid var(--#{$prefix}sidebar-border-color));
  box-shadow: none;
  @include transition($sidebar-transition);

  &:not(.sidebar-end){
    @include ltr-rtl("margin-left", 0);
    ~ * {
      --#{$prefix}sidebar-occupy-start: #{$sidebar-width};
    }
  }

  &.sidebar-end {
    order: 99;
    @include ltr-rtl("margin-right", 0);
    ~ * {
      --#{$prefix}sidebar-occupy-end: #{$sidebar-width};
    }
  }

  &[class*="bg-"]  {
    border-color: rgba($black, .1);
  }

  @each $width, $value in $sidebar-widths {
    &.sidebar-#{$width} {
      --#{$prefix}sidebar-width: #{$value};
      @include media-breakpoint-up($mobile-breakpoint) {
        &:not(.sidebar-end):not(.hide) {
          ~ * {
            --#{$prefix}sidebar-occupy-start: #{$value};
          }
        }
        &.sidebar-end:not(.hide) {
          ~ * {
            --#{$prefix}sidebar-occupy-end: #{$value};
          }
        }
      }
    }
  }

  @include media-breakpoint-up($mobile-breakpoint) {
    &.hide {
      &:not(.sidebar-end){
        @include ltr-rtl("margin-left", calc(-1 * var(--#{$prefix}sidebar-width)));
        ~ * {
          --#{$prefix}sidebar-occupy-start: 0;
        }
      }
      &.sidebar-end {
        @include ltr-rtl("margin-right", calc(-1 * var(--#{$prefix}sidebar-width)));
        ~ * {
          --#{$prefix}sidebar-occupy-end: 0;
        }
      }
    }
  }

  &.sidebar-fixed {
    @include media-breakpoint-up($mobile-breakpoint) {
      position: fixed;
      top: 0;
      bottom: 0;
      z-index: $zindex-fixed;
      @include elevation(2);

      &:not(.sidebar-end) {
        @include ltr-rtl("left", 0);
      }

      &.sidebar-end {
        @include ltr-rtl("right", 0);
      }
    }
  }

  &.sidebar-sticky {
    @include media-breakpoint-up($mobile-breakpoint) {
      position: sticky;
      top: 0;
      height: 100vh;
    }
  }

  &.sidebar-overlaid {
    position: fixed;
    top: 0;
    bottom: 0;
    z-index: $zindex-fixed + 2;

    &:not(.hide) {
      @include elevation(3);
    }

    &:not(.sidebar-end) {
      @include ltr-rtl("left", 0);
      ~ * {
        --#{$prefix}sidebar-occupy-start: 0 !important;  // stylelint-disable-line
      }
    }

    &.sidebar-end {
      @include ltr-rtl("right", 0);
      ~ * {
        --#{$prefix}sidebar-occupy-end: 0  !important; // stylelint-disable-line
      }
    }
  }

  @include media-breakpoint-down($mobile-breakpoint) {
    // Some of our components use this property to detect if the sidebar has mobile behavior.
    --#{$prefix}is-mobile: true;
    position: fixed;
    top: 0;
    bottom: 0;
    z-index: $zindex-fixed + 1;

    &:not(.sidebar-end) {
      @include ltr-rtl("left", 0);
      ~ * {
        --#{$prefix}sidebar-occupy-start: 0 !important; // stylelint-disable-line
      }

      &:not(.show) {
        @include ltr-rtl("margin-left", calc(-1 * var(--#{$prefix}sidebar-width)));
      }
    }
    &.sidebar-end {
      @include ltr-rtl("right", 0);
      ~ * {
        --#{$prefix}sidebar-occupy-end: 0 !important; // stylelint-disable-line
      }

      &:not(.show) {
        @include ltr-rtl("margin-right", calc(-1 * var(--#{$prefix}sidebar-width)));
      }
    }
  }
}

.sidebar-close {
  position: absolute;
  top: 0;
  @include ltr-rtl("right", 0);
  width: var(--#{$prefix}sidebar-width);
  height: var(--#{$prefix}sidebar-header-height);
  color: var(--#{$prefix}sidebar-color);
  background: transparent;
  border: 0;

  &:hover {
    text-decoration: none;
  }

  &:focus {
    outline: 0;
  }
}

.sidebar-brand {
  display: flex;
  flex: 0 0 var(--#{$prefix}sidebar-brand-height);
  align-items: center;
  justify-content: center;
  color: var(--#{$prefix}sidebar-brand-color);
  background: var(--#{$prefix}sidebar-brand-bg);

  .sidebar-brand-narrow {
    display: none;
  }
}

.sidebar-header {
  flex: 0 0 var(--#{$prefix}sidebar-header-height);
  padding: var(--#{$prefix}sidebar-header-padding-y) var(--#{$prefix}sidebar-header-padding-x);
  text-align: center;
  background: var(--#{$prefix}sidebar-header-bg);
  @include transition($sidebar-header-height-transition);

  .nav-link {
    display: flex;
    align-items: center;
    min-height: var(--#{$prefix}sidebar-header-height);
  }
}

.sidebar-footer {
  flex: 0 0 var(--#{$prefix}sidebar-footer-height);
  padding: var(--#{$prefix}sidebar-footer-padding-y) var(--#{$prefix}sidebar-footer-padding-x);
  background: var(--#{$prefix}sidebar-footer-bg);
  @include transition($sidebar-footer-height-transition);
}

.sidebar-toggler {
  display: flex;
  flex: 0 0 var(--#{$prefix}sidebar-toggler-height);
  justify-content: flex-end;
  width: inherit;
  padding: 0;
  cursor: pointer;
  background-color: var(--#{$prefix}sidebar-toggler-bg);
  border: 0;

  @include media-breakpoint-down($mobile-breakpoint) {
    display: none;
  }

  &::before {
    display: block;
    width: var(--#{$prefix}sidebar-toggler-indicator-width);
    height: var(--#{$prefix}sidebar-toggler-indicator-height);
    content: "";
    background-image: var(--#{$prefix}sidebar-toggler-indicator);
    background-repeat: no-repeat;
    background-position: center;
    background-size: calc(var(--#{$prefix}sidebar-toggler-indicator-height) * .25); // stylelint-disable-line function-disallowed-list
    @include transition($sidebar-toggler-transition);
    @include rtl() {
      transform: rotate(-180deg);
    }
  }

  &:focus {
    outline: 0;
  }

  &:hover {
    background-color: var(--#{$prefix}sidebar-toggler-hover-bg);
    &::before {
      background-image: var(--#{$prefix}sidebar-toggler-indicator-hover);
    }
  }

  .sidebar-end & {
    justify-content: flex-start;
    &::before {
      transform: rotate(-180deg);
    }
  }
}

// Backdrop background

.sidebar-backdrop {
  // scss-docs-start sidebar-backdrop-css-vars
  --#{$prefix}backdrop-zindex: #{$zindex-sidebar-backdrop};
  --#{$prefix}backdrop-bg: #{$sidebar-backdrop-bg};
  --#{$prefix}backdrop-opacity: #{$sidebar-backdrop-opacity};
  // scss-docs-end sidebar-backdrop-css-vars

  @include media-breakpoint-down($mobile-breakpoint) {
    @include overlay-backdrop(var(--#{$prefix}backdrop-zindex), var(--#{$prefix}backdrop-bg), var(--#{$prefix}backdrop-opacity));
  }
}
//
// Sidebar themes
//

.sidebar-light {
  --#{$prefix}sidebar-color: #{$sidebar-light-color};
  --#{$prefix}sidebar-bg: #{$sidebar-light-bg};
  --#{$prefix}sidebar-border-width: #{$sidebar-light-border-width};
  --#{$prefix}sidebar-border-color: #{$sidebar-light-border-color};
  --#{$prefix}sidebar-brand-color: #{$sidebar-light-brand-color};
  --#{$prefix}sidebar-brand-bg: #{$sidebar-light-brand-bg};
  --#{$prefix}sidebar-header-bg: #{$sidebar-light-header-bg};
  --#{$prefix}sidebar-nav-title-color: #{$sidebar-light-nav-title-color};
  --#{$prefix}sidebar-nav-link-color: #{$sidebar-light-nav-link-color};
  --#{$prefix}sidebar-nav-link-bg: #{$sidebar-light-nav-link-bg};
  --#{$prefix}sidebar-nav-link-icon-color: #{$sidebar-light-nav-link-icon-color};
  --#{$prefix}sidebar-nav-link-hover-color: #{$sidebar-light-nav-link-hover-color};
  --#{$prefix}sidebar-nav-link-hover-bg: #{$sidebar-light-nav-link-hover-bg};
  --#{$prefix}sidebar-nav-link-hover-icon-color: #{$sidebar-light-nav-link-hover-icon-color};
  --#{$prefix}sidebar-nav-link-active-color: #{$sidebar-light-nav-link-active-color};
  --#{$prefix}sidebar-nav-link-active-bg: #{$sidebar-light-nav-link-active-bg};
  --#{$prefix}sidebar-nav-link-active-icon-color: #{$sidebar-light-nav-link-active-icon-color};
  --#{$prefix}sidebar-nav-link-disabled-color: #{$sidebar-light-nav-link-disabled-color};
  --#{$prefix}sidebar-nav-link-disabled-icon-color: #{$sidebar-light-nav-link-disabled-icon-color};
  --#{$prefix}sidebar-nav-group-bg: #{$sidebar-light-nav-group-bg};
  --#{$prefix}sidebar-nav-group-toggle-show-color: #{$sidebar-light-nav-group-toggle-show-color};
  --#{$prefix}sidebar-nav-group-indicator: #{$sidebar-light-nav-group-indicator-icon};
  --#{$prefix}sidebar-nav-group-indicator-hover: #{$sidebar-light-nav-group-indicator-hover-icon};
  --#{$prefix}sidebar-footer-bg: #{$sidebar-light-footer-bg};
  --#{$prefix}sidebar-toggler-bg: #{$sidebar-light-toggler-bg};
  --#{$prefix}sidebar-toggler-hover-bg: #{$sidebar-light-toggler-hover-bg};
  --#{$prefix}sidebar-toggler-indicator: #{$sidebar-light-toggler-indicator-icon};
  --#{$prefix}sidebar-toggler-indicator-hover: #{$sidebar-light-toggler-indicator-hover-icon};
}

// Sidebar navigation

.sidebar-nav {
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 0;
  margin-bottom: 0;
  overflow-x: hidden;
  overflow-y: auto;
  list-style: none;

  .nav-title {
    padding: var(--#{$prefix}sidebar-nav-title-padding-y) var(--#{$prefix}sidebar-nav-title-padding-x);
    margin-top: var(--#{$prefix}sidebar-nav-title-margin-top);
    font-size: 80%;
    font-weight: 700;
    color: var(--#{$prefix}sidebar-nav-title-color);
    text-transform: uppercase;
    @include transition($sidebar-nav-title-transition);
  }

  .nav-link {
    display: flex;
    flex: 1;
    align-items: center;
    padding: var(--#{$prefix}sidebar-nav-link-padding-y) var(--#{$prefix}sidebar-nav-link-padding-x);
    color: $light;
    text-decoration: none;
    white-space: nowrap;
    background: var(--#{$prefix}sidebar-nav-link-bg);
    border: var(--#{$prefix}sidebar-nav-link-border);
    @include border-radius(var(--#{$prefix}sidebar-nav-link-border-radius));
    @include transition($sidebar-nav-link-transition);

    &.active {
      color: var(--#{$prefix}sidebar-nav-link-active-color);
      background: var(--#{$prefix}sidebar-nav-link-active-bg);

      .nav-icon {
        color: var(--#{$prefix}sidebar-nav-link-active-icon-color);
      }
    }

    &.disabled {
      color: var(--#{$prefix}sidebar-nav-link-disabled-color);
      pointer-events: none;
      cursor: not-allowed;
      background: transparent;

      .nav-icon {
        color: var(--#{$prefix}sidebar-nav-link-disabled-icon-color);
      }

      &:hover {
        color: var(--#{$prefix}sidebar-nav-link-disabled-color);

        .nav-icon {
          color: var(--#{$prefix}sidebar-nav-link-disabled-icon-color);
        }

        &.nav-dropdown-toggle::after {
          background-image: var(--#{$prefix}sidebar-nav-group-indicator-hover);
        }
      }
    }

    @media (hover: hover), (-ms-high-contrast: none) {
      &:hover {
        color: var(--#{$prefix}sidebar-nav-link-hover-color);
        text-decoration: none;
        background: var(--#{$prefix}sidebar-nav-link-hover-bg);

        .nav-icon {
          color: var(--#{$prefix}sidebar-nav-link-hover-icon-color);
        }

        &.nav-group-toggle::after {
          background-image: var(--#{$prefix}sidebar-nav-group-indicator-hover);
        }
      }
    }
  }

  .nav-icon {
    flex: 0 0 var(--#{$prefix}sidebar-nav-icon-width);
    height: var(--#{$prefix}sidebar-nav-icon-height);
    font-size: var(--#{$prefix}sidebar-nav-icon-font-size);
    color: var(--#{$prefix}sidebar-nav-link-icon-color);
    text-align: center;
    fill: currentColor;
    @include transition(inherit);

    &:first-child {
      @include ltr-rtl("margin-left", calc(-1*var(--#{$prefix}sidebar-nav-link-padding-x)));
    }
  }

  .nav-group {
    position: relative;
    @include transition($sidebar-nav-group-transition);

    .nav-group-items {
      padding: var(--#{$prefix}sidebar-nav-group-items-padding-y) var(--#{$prefix}sidebar-nav-group-items-padding-x);
      overflow: hidden;
      @include transition($sidebar-nav-group-items-transition);
    }

    &:not(.show) .nav-group-items {
      display: none;
    }

    &.show {
      background: var(--#{$prefix}sidebar-nav-group-bg);


      > .nav-group-toggle::after {
        transform: rotate(180deg);
      }

      + .show {
        margin-top: 1px;
      }
    }
  }

  .nav-group-toggle {
    cursor: pointer;

    &::after {
      display: block;
      flex: 0 12px;
      height: 12px;
      @include ltr-rtl("margin-left", auto);
      content: "";
      background-image: var(--#{$prefix}sidebar-nav-group-indicator);
      background-repeat: no-repeat;
      background-position: center;
      @include transition($sidebar-nav-group-indicator-transition);
    }
  }

  .nav-group-items {
    padding: 0;
    list-style: none;

    .nav-link {
      @include ltr-rtl("padding-left", var(--#{$prefix}sidebar-nav-icon-width));

      .nav-icon {
        @include ltr-rtl("margin-left", calc(-1*var(--#{$prefix}sidebar-nav-icon-width)));
      }
    }
  }

  &.compact,
  .compact {
    .nav-link {
      padding-top: calc(var(--#{$prefix}sidebar-nav-link-padding-y) * .5); // stylelint-disable-line function-disallowed-list
      padding-bottom: calc(var(--#{$prefix}sidebar-nav-link-padding-y) * .5); // stylelint-disable-line function-disallowed-list
    }
  }
}

%sidebar-narrow {
  @include media-breakpoint-up($mobile-breakpoint) {
    z-index: $zindex-fixed + 1;
    flex: 0 0 var(--#{$prefix}sidebar-narrow-width);
    width: var(--#{$prefix}sidebar-narrow-width);
    padding-bottom: var(--#{$prefix}sidebar-toggler-height);
    overflow: visible;

    &.sidebar-fixed {
      z-index: $zindex-fixed + 1;
      width: var(--#{$prefix}sidebar-narrow-width);
    }

    .sidebar-brand-full {
      display: none;
    }

    .sidebar-brand-narrow {
      display: block;
    }

    .d-narrow-none,
    .nav-label,
    .nav-title,
    .nav-group-items,
    .nav-group.show .nav-group-items,
    .sidebar-footer,
    .sidebar-form,
    .sidebar-header {
      height: 0 !important; // stylelint-disable-line declaration-no-important
      padding: 0;
      margin: 0;
      visibility: hidden;
      opacity: 0;
    }

    .sidebar-toggler {
      position: fixed;
      bottom: 0;

      &::before {
        @include ltr-rtl("transform", rotate(-180deg), null, rotate(0deg));
      }
    }

    &.sidebar-end .sidebar-toggler::before {
      transform: rotate(0deg);
    }
  }
}

.sidebar-narrow {
  @extend %sidebar-narrow;
  &:not(.sidebar-end) ~ * {
    --#{$prefix}sidebar-occupy-start: #{$sidebar-narrow-width};
  }
  &.sidebar-end ~ * {
    --#{$prefix}sidebar-occupy-end: #{$sidebar-narrow-width};
  }
}

.sidebar-narrow-unfoldable {
  position: fixed;
  z-index: $zindex-fixed + 1;

  &:not(.sidebar-end) ~ * {
    --#{$prefix}sidebar-occupy-start: #{$sidebar-narrow-width};
  }
  &.sidebar-end ~ * {
    --#{$prefix}sidebar-occupy-end: #{$sidebar-narrow-width};
  }

  &:not(:hover) {
    @extend %sidebar-narrow;
  }

  &:hover {
    .sidebar-toggler {
      &::before {
        @include ltr-rtl("transform", rotate(-180deg), null, rotate(0deg));
      }
    }

    &.sidebar-end .sidebar-toggler::before {
      transform: rotate(0deg);
    }
  }
}

// Responsive behavior
@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-down($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
    .sidebar:not(.show).sidebar-self-hiding#{$infix} {
      &.sidebar-narrow,
      &.sidebar-narrow-unfoldable {
        &:not(.sidebar-end) {
          @include ltr-rtl("margin-left", calc(-1*var(--#{$prefix}sidebar-narrow-width)));
        }
        &.sidebar-end {
          @include ltr-rtl("margin-right", calc(-1*var(--#{$prefix}sidebar-narrow-width)));
        }
      }
    }
  }
}
