// If you want to override variables do it here
@import 'variables';

$primary: #0039ec;
// $primary-gradient: #0000;
$secondary: #050505;
$danger: yellow;
$success: #0039ec;
$info: #3095AA;
$warning: #717171;
$danger: #ff0000;
$light: #ffffff;
$dark: #000;
$cobalt: #0064ff;
$indigo: #14235a;

$sidebar-bg: rgb(5, 63, 75);

$enable-ltr: true;
/* stylelint-disable-line scss/dollar-variable-default */
$enable-rtl: true;
/* stylelint-disable-line scss/dollar-variable-default */

// Configuration
@import '@coreui/coreui-pro/scss/functions';
@import '@coreui/coreui-pro/scss/variables';
@import '@coreui/coreui-pro/scss/maps';
@import '@coreui/coreui-pro/scss/mixins';
@import '@coreui/coreui-pro/scss/utilities';

// Layout & Components
@import '@coreui/coreui-pro/scss/root';
@import '@coreui/coreui-pro/scss/reboot';
@import '@coreui/coreui-pro/scss/type';
@import '@coreui/coreui-pro/scss/images';
@import '@coreui/coreui-pro/scss/containers';
@import '@coreui/coreui-pro/scss/grid';
@import '@coreui/coreui-pro/scss/tables';
@import '@coreui/coreui-pro/scss/forms';
@import '@coreui/coreui-pro/scss/buttons';
@import '@coreui/coreui-pro/scss/loading-button';
@import '@coreui/coreui-pro/scss/transitions';
@import '@coreui/coreui-pro/scss/dropdown';
@import '@coreui/coreui-pro/scss/button-group';
// @import '@coreui/coreui-pro/scss/nav';
@import '@coreui/coreui-pro/scss/navbar';
@import '@coreui/coreui-pro/scss/card';
@import '@coreui/coreui-pro/scss/breadcrumb';
@import '@coreui/coreui-pro/scss/pagination';
@import '@coreui/coreui-pro/scss/badge';
@import '@coreui/coreui-pro/scss/alert';
@import '@coreui/coreui-pro/scss/progress';
@import '@coreui/coreui-pro/scss/close';
// @import '@coreui/coreui-pro/scss/modal';
@import '@coreui/coreui-pro/scss/tooltip';
@import '@coreui/coreui-pro/scss/carousel';
@import '@coreui/coreui-pro/scss/spinners';
// @import '@coreui/coreui-pro/scss/offcanvas';
@import '@coreui/coreui-pro/scss/placeholders';
@import '@coreui/coreui-pro/scss/avatar';
@import '@coreui/coreui-pro/scss/calendar';
@import '@coreui/coreui-pro/scss/callout';
@import '@coreui/coreui-pro/scss/picker';
@import '@coreui/coreui-pro/scss/date-picker';
@import '@coreui/coreui-pro/scss/time-picker';
@import '@coreui/coreui-pro/scss/footer';
@import '@coreui/coreui-pro/scss/icon';
@import '@coreui/coreui-pro/scss/subheader';

// Helpers
@import '@coreui/coreui-pro/scss/helpers';

// Utilities & Fonts
@import '@coreui/coreui-pro/scss/utilities/api';
@import '@coreui/coreui-pro/scss/utilities/bg-gradients';
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&family=Source+Sans+Pro:wght@300;400;600;700;900&display=swap');

@import 'layout';

// If you want to add custom CSS you can put it here.
@import 'custom';
@import 'sidebar';
@import 'header';
@import 'nav';
@import 'modal';

html,
body {
  font-family: 'Source Sans Pro', sans-serif !important;
}

.hover {
  cursor: pointer;
}

.list-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(175px, 1fr));
}

.list-grid-item {
  margin: 5px 10px;
  white-space: nowrap;
  overflow: hidden;

  &>label {
    text-overflow: ellipsis;
  }
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23000' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23000' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E");
}

.PhoneInput {
  &>input {
    padding: 4px;
  }
}

.filter-input {
  width: 100px;
}

.multiselect-input {
  width: 500px;
}

.date-input {
  width: 250px;
}

.first-name {
  margin: 0 1rem 0 0;
}

.public-reports-container {
  min-height: 500px;
  max-height: 500px;
  overflow: scroll;
  margin: 10px 0;
}

.null-filter {
  display: flex;
  align-items: center;
  margin-left: 10px;
  width: max-content;
  height: 100%;

  &>input {
    margin-left: 10px;
  }
}

.text-overflow {
  text-overflow: ellipsis;
  overflow: hidden;
}

.bg-cobalt {
  background-color: $cobalt;
}

.bg-indigo {
  background-color: $indigo;
}

.pretty-file input[type="file"] {
  display: none;
}

.text-align-center {
  text-align: center;
}

.cursor-pointer {
  cursor: pointer;
}